import Vue from 'vue'
import axios from 'axios'

export default {
  auth: {

    login(email, password) {
      return Vue.prototype.$axios({
        url: 'api/auth/login',
        method: "GET",
        params: {
          "email":email,
          "password":password
        }
      })
        .then(function (response) {
          return response
        })
        .catch(function (error) {
          return error
        });
    },
  },
  JwtConfig(config)
  {
    let configNew={
      url:config.url,
      data:config.data,
      method:config.method,
          headers: {
            Authorization: "Bearer " + localStorage.getItem('jwt')
          }
    }
    return configNew;
  },
  dodajKorisnika(user)
  {
    let server = process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH;
    let config = {
      url: server+'/users/postUser',
      data: {
        ime:user.ime,
        prezime:user.prezime,
        email:user.email,
        telefon:user.telefon,
        usertype:user.usertype,
      },
      method: "POST"
    };
    config=this.JwtConfig(config);
    axios(config)
        .then(function (response) {
          let loginResponse = response.data;
          return loginResponse;

        })

        .catch(function (error) {
          console.log("error", error)
        });
  },
  activate(id)
  {
    let server = process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH;
    let config = {
      url: server+'/users/activateUser',
      data: {
        id:id,
      },
      method: "POST"
    };
    config=this.JwtConfig(config);
    axios(config)
        .then(function (response) {
          let loginResponse = response.data;
          return loginResponse;

        })

        .catch(function (error) {
          console.log("error", error)
        });
  },
  deactivate(id)
  {
    let server = process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH;
    let config = {
      url: server+'/users/deactivateUser',
      data: {
        id:id,
      },
      method: "POST"
    };
    config=this.JwtConfig(config);
    axios(config)
        .then(function (response) {
          let loginResponse = response.data;
          return loginResponse;

        })

        .catch(function (error) {
          console.log("error", error)
        });
  },
  getKorisnici()
  {
    let server = process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH;
    return Vue.prototype.$axios({
      url: server+'/users/getUsers',
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwt')
      }
    }).then(function (response) {
      return response
    })
        .catch(function (error) {
          return error
        });

  },
  getKorisnik(id)
  {
    let server = process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH;
    return Vue.prototype.$axios({
      url: server+'/users/getUser/'+id,
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwt')
      }
    }).then(function (response) {
      return response
    })
        .catch(function (error) {
          return error
        });

  },
  getKorisnikUplate(id)
  {
    let server = process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH;
    return Vue.prototype.$axios({
      url: server+'/uplate/getUserPayments/'+id,
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwt')
      }
    }).then(function (response) {
      return response
    })
        .catch(function (error) {
          return error
        });

  },
  getUplate()
  {
    let server = process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH;
    return Vue.prototype.$axios({
      url: server+'/uplate/getPaymentsList',
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwt')
      }
    }).then(function (response) {
      return response
    })
        .catch(function (error) {
          return error
        });

  },
  GetTestovi()
  {
    let server = process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH;
    return Vue.prototype.$axios({
      url: server+'/tests/getTestList',
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwt')
      }
    }).then(function (response) {
      return response
    })
        .catch(function (error) {
          return error
        });
  },
  getAllTests()
  {
    let server = process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH;
    return Vue.prototype.$axios({
      url: server+'/tests/getTestListUser',
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwt')
      }
    }).then(function (response) {
      return response
    })
        .catch(function (error) {
          return error
        });
  },
  GetTestoviUser(ID)
  {
    let server = process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH;
    return Vue.prototype.$axios({
      url: server+'/tests/getUserTests/'+ID,
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwt')
      }
    }).then(function (response) {
      return response
    })
        .catch(function (error) {
          return error
        });
  },
  GetTest(ID)
  {
    let server = process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH;
    return Vue.prototype.$axios({
      url: server+'/tests/getTest/'+ID,
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwt')
      }
    }).then(function (response) {
      return response
    })
        .catch(function (error) {
          return error
        });
  },
  GetTestoviUserTest(UID,ID)
  {
    let server = process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH;
    return Vue.prototype.$axios({
      url: server+'/tests/getUserTest/'+UID+'/'+ID,
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwt')
      }
    }).then(function (response) {
      return response
    })
        .catch(function (error) {
          return error
        });
  },
  postKorisnikUplata(id,kolicina)
  {
    let server = process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH;
    return Vue.prototype.$axios({
      url: server+'/uplate/postUplata',
      method: "POST",
      data: {
        user_id:id,
        value:kolicina,
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwt')
      }
    }).then(function (response) {
      return response
    })
        .catch(function (error) {
          return error
        });

  },
  postKorisnikTest(id,Questions,tid)
  {
    let server = process.env.VUE_APP_SERVER_ROOT + process.env.VUE_APP_API_PATH;
    return Vue.prototype.$axios({
      url: server+'/tests/postTestComplete',
      method: "POST",
      data: {
        Questions:Questions,
        uid:id,
        tid:tid,
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem('jwt')
      }
    }).then(function (response) {
      window.scroll(0,0);
      return response
    })
        .catch(function (error) {
          return error
        });
  }

}
